import { ApolloError } from '@apollo/client/errors'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apolloClient } from 'src/apollo/client'
import {
	CONFIRM_RESET_USER_PASSWORD,
	CONFIRM_USER_EMAIL,
	CREATE_USER,
	LOGIN_USER,
	LOGIN_USER_BY_TOKEN,
	LOGOUT_USER,
	REGISTER_ADMIN,
	REGISTER_USER,
	UPDATE_ADMIN,
	UPDATE_USER
} from 'src/graphql/mutation'
import { IS_AUTHENTICATED } from 'src/graphql/query'
import { RequestError } from 'src/graphql/types'
import {
	ConfirmResetPasswordReturnedData,
	ConfirmResetPasswordVariables,
	ConfirmUserEmailReturnedData,
	ConfirmUserEmailVariables,
	CreateUserReturnedData,
	CreateUserVariables,
	LoginByTokenReturnedData,
	LoginByTokenVariables,
	LoginReturnedData,
	Payload,
	RegisterAdminReturnedData,
	RegisterAdminVariables,
	RegisterUserReturnedData,
	RegisterUserVariables,
	UpdateAdminReturnedData,
	UpdateAdminVariables,
	UpdateUserReturnedData,
	UpdateUserVariables
} from './auth.types'

export const loginUser = createAsyncThunk<
	LoginReturnedData,
	{ email: string; password: string },
	{
		rejectValue: RequestError
	}
>('auth/loginUser', async ({ email, password }, { rejectWithValue }) => {
	try {
		const response = await apolloClient.mutate({
			mutation: LOGIN_USER,
			variables: {
				email: email.trim(),
				password
			}
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})

export const registerAdmin = createAsyncThunk<
	RegisterAdminReturnedData,
	RegisterAdminVariables,
	{
		rejectValue: RequestError
	}
>('auth/registerAdmin', async (variables, { rejectWithValue }) => {
	try {
		const response = await apolloClient.mutate({
			mutation: REGISTER_ADMIN,
			variables
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})

export const confirmResetPassword = createAsyncThunk<
	ConfirmResetPasswordReturnedData,
	ConfirmResetPasswordVariables,
	{
		rejectValue: RequestError
	}
>('auth/confirmResetPassword', async (variables, { rejectWithValue }) => {
	try {
		const response = await apolloClient.mutate({
			mutation: CONFIRM_RESET_USER_PASSWORD,
			variables
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})

export const registerUser = createAsyncThunk<
	RegisterUserReturnedData,
	RegisterUserVariables,
	{
		rejectValue: RequestError
	}
>('auth/registerUser', async (variables, { rejectWithValue }) => {
	try {
		const response = await apolloClient.mutate({
			mutation: REGISTER_USER,
			variables
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})

export const logoutUser = createAsyncThunk<
	{ logoutUser: { ok: boolean } },
	void,
	{
		rejectValue: RequestError
	}
>('auth/logoutUser', async (_, { rejectWithValue }) => {
	try {
		const response = await apolloClient.mutate({
			mutation: LOGOUT_USER
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})

export const checkAuth = createAsyncThunk<
	Payload,
	void,
	{
		rejectValue: ApolloError
	}
>('auth/checkAuth', async (_, { rejectWithValue }) => {
	try {
		const response = await apolloClient.query({
			query: IS_AUTHENTICATED
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as ApolloError)
	}
})

export const updateUser = createAsyncThunk<
	UpdateUserReturnedData,
	UpdateUserVariables,
	{
		rejectValue: RequestError
	}
>('auth/updateUser', async (variables, { rejectWithValue }) => {
	try {
		const response = await apolloClient.mutate({
			mutation: UPDATE_USER,
			variables
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})

export const createUser = createAsyncThunk<
	CreateUserReturnedData,
	CreateUserVariables,
	{
		rejectValue: RequestError
	}
>('auth/createUser', async ({ email, ...variables }, { rejectWithValue }) => {
	try {
		const response = await apolloClient.mutate({
			mutation: CREATE_USER,
			variables: {
				...variables,
				email: email.trim()
			}
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})

export const confirmUserEmail = createAsyncThunk<
	ConfirmUserEmailReturnedData,
	ConfirmUserEmailVariables,
	{
		rejectValue: RequestError
	}
>('auth/confirmUserEmail', async (variables, { rejectWithValue }) => {
	try {
		const response = await apolloClient.mutate({
			mutation: CONFIRM_USER_EMAIL,
			variables
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})

export const loginUserByToken = createAsyncThunk<
	LoginByTokenReturnedData,
	LoginByTokenVariables,
	{
		rejectValue: RequestError
	}
>('auth/loginByToken', async (variables, { rejectWithValue }) => {
	try {
		const response = await apolloClient.mutate({
			mutation: LOGIN_USER_BY_TOKEN,
			variables
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})

export const updateAdmin = createAsyncThunk<
	UpdateAdminReturnedData,
	UpdateAdminVariables,
	{
		rejectValue: RequestError
	}
>('auth/updateAdmin', async (variables, { rejectWithValue }) => {
	try {
		const response = await apolloClient.mutate({
			mutation: UPDATE_ADMIN,
			variables
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})
