import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CustomSelectValue } from 'src/components/elements/CustomSelect/CustomSelect.types'
import { defaultFilters } from 'src/utils/parentFilterOptions'
import type { RootState } from '../store'
import {
	LicenceStatus,
	State,
	StatusPayload,
	LicenceType,
	ParentFilters
} from './parentTable.types'

const initialState: State = {
	page: 1,
	perPage: 10,
	orderBy: 'NAME_AND_EMAIL',
	sortBy: 'ASC',
	status: 'ALL',
	search: '',
	filteredQuantity: 0,
	...defaultFilters,
	shouldUpdate: false
}

const table = createSlice({
	name: 'parentTable',
	initialState,
	reducers: {
		setParentTablePage(state: State, action: PayloadAction<number>) {
			state.page = action.payload
		},
		setParentTablePerPage(state: State, action: PayloadAction<number>) {
			state.perPage = action.payload
		},
		setParentTableSearch(state: State, action: PayloadAction<string>) {
			state.search = action.payload
		},
		setParentTableStatus(state: State, action: PayloadAction<StatusPayload>) {
			state.status = action.payload
		},
		setParentTableSortByNameDesc(state: State) {
			state.sortBy = 'DESC'
			state.orderBy = 'NAME'
		},
		setParentTableSortByNameAsc(state: State) {
			state.sortBy = 'ASC'
			state.orderBy = 'NAME'
		},
		setParentTableSortByEmailDesc(state: State) {
			state.sortBy = 'DESC'
			state.orderBy = 'EMAIL'
		},
		setParentTableSortByEmailAsc(state: State) {
			state.sortBy = 'ASC'
			state.orderBy = 'EMAIL'
		},
		setSchool(state: State, action: PayloadAction<CustomSelectValue>) {
			state.school = action.payload
		},
		setPolicy(state: State, action: PayloadAction<CustomSelectValue>) {
			state.policy = action.payload
		},
		setLicenceStatus(state: State, action: PayloadAction<CustomSelectValue<LicenceStatus>>) {
			state.licenceStatus = action.payload
		},
		setLicenceType(state: State, action: PayloadAction<CustomSelectValue<LicenceType>>) {
			state.licenceType = action.payload
		},
		setParentFilters(state: State, action: PayloadAction<ParentFilters>) {
			state.school = action.payload.school
			state.policy = action.payload.policy
			state.licenceType = action.payload.licenceType
			state.licenceStatus = action.payload.licenceStatus
		},
		resetFilters(state: State) {
			state.school = defaultFilters.school
			state.policy = defaultFilters.policy
			state.licenceStatus = defaultFilters.licenceStatus
			state.licenceType = defaultFilters.licenceType
		},
		setParentTableShouldUpdate(state: State, action: PayloadAction<boolean>) {
			state.shouldUpdate = action.payload
		},
		setFilteredQuantity(state: State, action: PayloadAction<number>) {
			state.filteredQuantity = action.payload
		}
	}
})
export const {
	setParentTablePage,
	setParentTablePerPage,
	setParentTableSearch,
	setParentTableStatus,
	setParentTableSortByNameDesc,
	setParentTableSortByNameAsc,
	setParentTableSortByEmailDesc,
	setParentTableSortByEmailAsc,
	setParentTableShouldUpdate,
	setPolicy,
	setSchool,
	setLicenceStatus,
	setLicenceType,
	resetFilters,
	setParentFilters,
	setFilteredQuantity
} = table.actions
export const selectParentTable = (state: RootState) => state.parentTable
export const selectParentFilters = ({
	parentTable: { school, policy, licenceType, licenceStatus }
}: RootState) => ({ school, policy, licenceType, licenceStatus })

export default table.reducer
