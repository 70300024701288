import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { TASK_STATUS } from 'src/graphql/query'
import {
	clearParentExportId,
	clearReportingExportId
} from 'src/store/backgroundActivity/backgroundActivity.slice'
import { enqueueSnackbar } from 'notistack'
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'

const BackgroundExport: React.FC = () => {
	const dispatch = useAppDispatch()
	const { parentExportId, reportingExportId } = useAppSelector(
		(state) => state.backgroundActivity
	)
	const [getExportStatus] = useLazyQuery(TASK_STATUS)

	const handleTaskStatus = (exportId: string, dispatchAction: ActionCreatorWithoutPayload) => {
		return setInterval(async () => {
			const { data } = await getExportStatus({
				variables: {
					id: exportId
				}
			})

			if (data.taskStatus.status === 'FAILURE') {
				enqueueSnackbar('Failed to download file. Please try again.', {
					variant: 'error'
				})
				dispatch(dispatchAction())

				return
			}

			if (data.taskStatus.status === 'SUCCESS') {
				const link = document.createElement('a')

				link.href = data.taskStatus.url
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)

				dispatch(dispatchAction())
			}
		}, 1000)
	}

	useEffect(() => {
		let interval: NodeJS.Timer

		if (parentExportId) {
			interval = handleTaskStatus(parentExportId, clearParentExportId)
		}

		return () => {
			clearInterval(interval)
		}
	}, [parentExportId])

	useEffect(() => {
		let interval: NodeJS.Timer

		if (reportingExportId) {
			interval = handleTaskStatus(reportingExportId, clearReportingExportId)
		}

		return () => {
			clearInterval(interval)
		}
	}, [reportingExportId])

	return null
}

export default BackgroundExport
