import Loader from 'src/components/elements/Loader'

const PageLoading: React.FC = () => {
	return (
		<div className="page-loader">
			<Loader width="100px" color="grey" />
		</div>
	)
}

export default PageLoading
