import { createAsyncThunk } from '@reduxjs/toolkit'
import { RequestError } from 'src/graphql/types'
import { apolloClient } from 'src/apollo/client'
import { GET_BASE_POLICY, GET_POLICY } from 'src/graphql/query'
import { BasePolicyReturnedData, PolicyReturnedData } from './policy.types'

export const getBasePolicy = createAsyncThunk<BasePolicyReturnedData>(
	'policy/getBasePolicy',
	async (_, { rejectWithValue }) => {
		try {
			const response = await apolloClient.query({
				query: GET_BASE_POLICY,
				fetchPolicy: 'no-cache'
			})
			return response.data
		} catch (e) {
			return rejectWithValue(e as RequestError)
		}
	}
)

export const getPolicy = createAsyncThunk<PolicyReturnedData, string>(
	'policy/getPolicy',
	async (id, { rejectWithValue }) => {
		try {
			const response = await apolloClient.query({
				query: GET_POLICY,
				variables: { id },
				fetchPolicy: 'network-only'
			})
			return response.data
		} catch (e) {
			return rejectWithValue(e as RequestError)
		}
	}
)
