import { configureStore } from '@reduxjs/toolkit'
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from 'redux-persist'
import createFilter from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage'
import authSlice from './auth/auth.slice'
import siteInfo from './siteInfo/siteInfo.slice'
import parentTable from './parentTable/parentTable.slice'
import steps from './steps/steps.slice'
import policiesTable from './policiesTable/policiesTable.slice'
import policy from './policy/policy.slice'
import adminsTable from './adminsTable/adminsTable.slice'
import backgroundActivity from './backgroundActivity/backgroundActivity.slice'

const userProperties = createFilter(`user`, ['isAuthenticated', 'firstName', 'lastName'])

const persistConfig = {
	key: 'persist',
	storage,
	whitelist: ['user', 'steps'],
	transforms: [userProperties]
}

const authReducer = persistReducer(persistConfig, authSlice)

export const store = configureStore({
	reducer: {
		auth: authReducer,
		siteInfo,
		parentTable,
		steps,
		policiesTable,
		policy,
		adminsTable,
		backgroundActivity
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		})
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
