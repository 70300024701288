import successIcon from 'src/assets/images/icons/success.svg'
import infoIcon from 'src/assets/images/icons/info.svg'
import errorIcon from 'src/assets/images/icons/error.svg'
import React from 'react'
import { CustomContentProps, SnackbarContent } from 'notistack'
import styles from './Snackbar.module.scss'

const icons = {
	success: successIcon,
	info: infoIcon,
	error: errorIcon
}
const Snackbar = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
	const { id, message, variant, ...other } = props

	return (
		<SnackbarContent ref={ref} {...other}>
			<div className={`${styles.snackbar} ${[styles[variant]]}`} key={id}>
				<img
					src={icons[variant]}
					className={styles.snackbar__icon}
					alt={message as string}
				/>
				<p className={styles.snackbar__text}>{message}</p>
			</div>
		</SnackbarContent>
	)
})

export default Snackbar
