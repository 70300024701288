import { createAsyncThunk } from '@reduxjs/toolkit'
import { apolloClient } from 'src/apollo/client'
import { GET_SITE_INFO } from 'src/graphql/query'
import { RequestError } from 'src/graphql/types'
import type { State } from './siteInfo.slice'

const getSiteInfo = createAsyncThunk<
	{ siteInfo: State },
	void,
	{
		rejectValue: RequestError
	}
>('auth/loginUser', async (_, { rejectWithValue }) => {
	try {
		const response = await apolloClient.query({
			query: GET_SITE_INFO
		})
		return response.data
	} catch (e) {
		return rejectWithValue(e as RequestError)
	}
})
export default getSiteInfo
