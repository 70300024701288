import { gql } from '@apollo/client'
import { FRAGMENT_USER } from '../fragments'

export const CREATE_USER = gql`
	mutation createUser(
		$address: LimitedString!
		$city: LimitedString!
		$codes: [String]!
		$countryId: ID!
		$email: LowerString!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$password1: LimitedString!
		$password2: LimitedString!
		$phoneNumber: LimitedString!
		$postCode: LimitedString!
		$termsOfUse: Boolean!
	) {
		createUser(
			address: $address
			city: $city
			codes: $codes
			countryId: $countryId
			email: $email
			firstName: $firstName
			lastName: $lastName
			password1: $password1
			password2: $password2
			phoneNumber: $phoneNumber
			postCode: $postCode
			termsOfUse: $termsOfUse
		) {
			user {
				...User
			}
			ok
			errors {
				fieldName
				errors
			}
			licences {
				activationDate
				status
				isValid
				code
				profileCode
			}
		}
	}
	${FRAGMENT_USER}
`
export const UPDATE_USER = gql`
	mutation updateUser(
		$address: LimitedString!
		$city: LimitedString!
		$countryId: ID!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$phoneNumber: LimitedString!
		$postCode: LimitedString!
	) {
		updateUser(
			address: $address
			city: $city
			countryId: $countryId
			firstName: $firstName
			lastName: $lastName
			phoneNumber: $phoneNumber
			postCode: $postCode
		) {
			user {
				...User
			}
			ok
			errors {
				fieldName
				errors
			}
		}
	}
	${FRAGMENT_USER}
`

export const LOGIN_USER = gql`
	mutation loginUser($email: LowerString!, $password: LimitedString!) {
		loginUser(email: $email, password: $password) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const LOGIN_USER_BY_TOKEN = gql`
	mutation loginUserByToken($token: String!, $uidb64: String!) {
		loginUserByToken(token: $token, uidb64: $uidb64) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const CREATE_CHILD = gql`
	mutation createChild($children: [ChildParentInput]) {
		createChild(children: $children) {
			ok
			agreement
			errors {
				childErrors {
					fieldName
					errors
				}
			}
		}
	}
`

export const LOGOUT_USER = gql`
	mutation logoutUser {
		logoutUser {
			ok
		}
	}
`

export const UPDATE_PASSWORD = gql`
	mutation updateUserPassword(
		$oldPassword: LimitedString!
		$password1: LimitedString!
		$password2: LimitedString!
	) {
		updateUserPassword(
			oldPassword: $oldPassword
			password1: $password1
			password2: $password2
		) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const RESET_USER_PASSWORD = gql`
	mutation resetUserPassword($email: LowerString!) {
		resetUserPassword(email: $email) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CONFIRM_RESET_USER_PASSWORD = gql`
	mutation confirmResetUserPassword(
		$password1: LimitedString!
		$password2: LimitedString!
		$token: String!
		$uidb64: String!
	) {
		confirmResetUserPassword(
			password1: $password1
			password2: $password2
			token: $token
			uidb64: $uidb64
		) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const UPDATE_USER_EMAIL = gql`
	mutation updateUserEmail($email: LowerString!, $password: LimitedString!) {
		updateUserEmail(email: $email, password: $password) {
			user {
				email
			}
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CONFIRM_USER_EMAIL = gql`
	mutation confirmUserEmail($token: String!, $uidb64: String!) {
		confirmUserEmail(token: $token, uidb64: $uidb64) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const UPDATE_CHILD = gql`
	mutation updateChild($childId: ID, $name: LimitedString, $policyId: ID, $agreed: Boolean) {
		updateChild(childId: $childId, name: $name, policyId: $policyId, agreed: $agreed) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const CREATE_ANONYMOUS_USER = gql`
	mutation createAnonymousUser($codes: [String], $extraLicenceQty: Int) {
		createAnonymousUser(codes: $codes, extraLicenceQty: $extraLicenceQty) {
			ok
			errors {
				fieldName
				errors
			}
			licenceIsValid {
				isValid
				code
				error
			}
		}
	}
`

export const CREATE_PAYMENT = gql`
	mutation createPayment {
		createPayment {
			paymentUrl
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const ADD_LICENCES = gql`
	mutation add_lic($codes: [String]!) {
		addLicence(codes: $codes) {
			licenceIsValid {
				isValid
				code
				error
			}
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const ADD_EXTRA_LICENCES = gql`
	mutation add_extra_lic($codes: [String], $extraLicenceQty: Int!) {
		addExtraLicence(codes: $codes, extraLicenceQty: $extraLicenceQty) {
			user {
				licences {
					totalAmount
					codes
				}
			}
			licenceIsValid {
				isValid
				code
				error
			}
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const DELETE_ANONYMOUS_USER = gql`
	mutation deleteAnonymousUser {
		deleteAnonymousUser {
			ok
		}
	}
`

export const DELETE_EXTRA_LICENCES = gql`
	mutation deleteNewExtraLicences {
		deleteNewExtraLicences {
			ok
		}
	}
`

export const SET_REC_TIME = gql`
	mutation setRecreationTime($childId: ID!, $recreationTimes: [RecreationTimeInput]) {
		setRecreationTime(childId: $childId, recreationTimes: $recreationTimes) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const SET_DAY_REC_TIME = gql`
	mutation setDayRecreationTime($childId: ID!, $timeIntervals: [TimeIntervalInput]) {
		setDayRecreationTime(childId: $childId, timeIntervals: $timeIntervals) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const SET_REC_TIME_MANUALLY = gql`
	mutation setRecreationTimeManually($childId: ID!, $recreationTimes: [RecreationTimeInput]) {
		setRecreationTimeManually(childId: $childId, recreationTimes: $recreationTimes) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const RESET_EXTEND_TIME = gql`
	mutation resetRecreationTime($childId: ID!, $recreationTimeId: ID) {
		resetRecreationTime(childId: $childId, recreationTimeId: $recreationTimeId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const RESET_EXTEND_TIME_MANUALLY = gql`
	mutation resetRecreationTimeManually($childId: ID!, $recreationTimeId: ID) {
		resetRecreationTimeManually(childId: $childId, recreationTimeId: $recreationTimeId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_PARENT_DASHBOARD = gql`
	mutation createParentDashboard(
		$email: LowerString!
		$firstName: LimitedString!
		$lastName: LimitedString!
	) {
		createParentDashboard(email: $email, firstName: $firstName, lastName: $lastName) {
			ok
			user {
				id
			}
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_ADMIN_DASHBOARD = gql`
	mutation createAdminDashboard(
		$email: LowerString!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$school: ID
	) {
		createAdminDashboard(
			email: $email
			firstName: $firstName
			lastName: $lastName
			school: $school
		) {
			ok
			user {
				id
			}
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_PROGRESS_LICENCES = gql`
	mutation createInProgressLicences($amount: Int, $parentId: ID) {
		createInProgressLicences(amount: $amount, parentId: $parentId) {
			ok
			licences {
				code
				status
			}
		}
	}
`

export const CREATE_CHILD_DASHBOARD = gql`
	mutation createChildDashboard($children: [ChildInput], $parentId: ID) {
		createChildDashboard(children: $children, parentId: $parentId) {
			ok
			errors {
				childErrors {
					fieldName
					errors
				}
			}
		}
	}
`

export const UPDATE_CHILD_DASHBOARD = gql`
	mutation updateChildDashboard($childId: ID, $name: LimitedString, $policyId: ID) {
		updateChildDashboard(childId: $childId, name: $name, policyId: $policyId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const UPDATE_PARENT_DASHBOARD = gql`
	mutation updateParentDashboard(
		$email: LowerString!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$id: ID!
	) {
		updateParentDashboard(email: $email, firstName: $firstName, lastName: $lastName, id: $id) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_EMAILS = gql`
	mutation createEmails($emails: [LowerString]!, $licencePeriod: Int, $licenceQuantity: Int) {
		createEmails(
			emails: $emails
			licencePeriod: $licencePeriod
			licenceQuantity: $licenceQuantity
		) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const DELETE_PARENT_DASHBOARD = gql`
	mutation deleteParentDashboard($parentId: ID!) {
		deleteParentDashboard(parentId: $parentId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const DELETE_CHILD_DASHBOARD = gql`
	mutation deleteChildDashboard($childId: ID!) {
		deleteChildDashboard(childId: $childId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const REGISTER_ADMIN = gql`
	mutation registerAdmin(
		$firstName: LimitedString!
		$lastName: LimitedString!
		$password1: LimitedString!
		$password2: LimitedString!
		$schoolPolicy: Boolean!
		$termsOfUse: Boolean!
	) {
		registerAdmin(
			firstName: $firstName
			lastName: $lastName
			password1: $password1
			password2: $password2
			schoolPolicy: $schoolPolicy
			termsOfUse: $termsOfUse
		) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`
export const REGISTER_USER = gql`
	mutation registerUser(
		$address: LimitedString!
		$city: LimitedString!
		$countryId: ID!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$password1: LimitedString!
		$password2: LimitedString!
		$phoneNumber: LimitedString!
		$postCode: LimitedString!
		$termsOfUse: Boolean!
	) {
		registerUser(
			address: $address
			city: $city
			countryId: $countryId
			firstName: $firstName
			lastName: $lastName
			password1: $password1
			password2: $password2
			phoneNumber: $phoneNumber
			postCode: $postCode
			termsOfUse: $termsOfUse
		) {
			ok
			errors {
				fieldName
				errors
			}
			user {
				...User
			}
		}
	}
	${FRAGMENT_USER}
`

export const UPDATE_ADMIN = gql`
	mutation updateAdmin($firstName: LimitedString!, $lastName: LimitedString!) {
		updateAdmin(firstName: $firstName, lastName: $lastName) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const UPLOAD_CSV_FILE = gql`
	mutation createParentCsvDashboard($csvFile: Upload!) {
		createParentCsvDashboard(csvFile: $csvFile) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_CHILD_PAYMENT = gql`
	mutation createChildPayment($childId: ID!) {
		createChildPayment(childId: $childId) {
			paymentUrl
			ok
			errors {
				errors
				fieldName
			}
		}
	}
`

export const DELETE_POLICY = gql`
	mutation deleteProfileDashboard($id: ID!) {
		deleteProfileDashboard(id: $id) {
			ok
			errors {
				errors
				fieldName
			}
		}
	}
`

export const CREATE_POLICY = gql`
	mutation createProfileDashboard(
		$name: LimitedString!
		$recreationTimes: [RecreationTimeInput]
		$services: [ServiceInputType]
		$privacies: [ID]
		$categories: [ID]
		$whiteList: [String]
		$blackList: [String]
		$activeDomains: [ActiveDomainInputType]
	) {
		createProfileDashboard(
			name: $name
			recreationTimes: $recreationTimes
			services: $services
			privacies: $privacies
			categories: $categories
			whiteList: $whiteList
			blackList: $blackList
			activeDomains: $activeDomains
		) {
			ok
			errors {
				errors
				fieldName
			}
		}
	}
`

export const UPDATE_POLICY = gql`
	mutation updateProfileDashboard(
		$name: LimitedString!
		$recreationTimes: [RecreationTimeInput]
		$activeDomains: [ActiveDomainInputType]
		$services: [ServiceInputType]
		$privacies: [ID]
		$categories: [ID]
		$whiteList: [String]
		$blackList: [String]
		$isActive: Boolean
		$orderNumber: Int
		$populateChanges: Boolean
		$id: ID!
	) {
		updateProfileDashboard(
			name: $name
			recreationTimes: $recreationTimes
			activeDomains: $activeDomains
			services: $services
			privacies: $privacies
			categories: $categories
			whiteList: $whiteList
			blackList: $blackList
			populateChanges: $populateChanges
			isActive: $isActive
			orderNumber: $orderNumber
			id: $id
		) {
			ok
			errors {
				errors
				fieldName
			}
		}
	}
`
export const UPDATE_ADMIN_DASHBOARD = gql`
	mutation updateAdminDashboard(
		$email: LowerString!
		$firstName: LimitedString!
		$lastName: LimitedString!
		$id: ID!
	) {
		updateAdminDashboard(email: $email, firstName: $firstName, lastName: $lastName, id: $id) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const DELETE_ADMIN_DASHBOARD = gql`
	mutation deleteAdminDashboard($id: ID!) {
		deleteAdminDashboard(id: $id) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
export const CREATE_ADMIN_EMAILS = gql`
	mutation createAdminEmails($emails: [LowerString]!, $school: ID) {
		createAdminEmails(emails: $emails, school: $school) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_EXTRA_EMAIL = gql`
	mutation createExtraEmails($emails: [LowerString]!) {
		createExtraEmails(emails: $emails) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const CREATE_EXTRA_EMAIL_DASHBOARD = gql`
	mutation createExtraEmailsDashboard($emails: [LowerString]!, $parentId: ID!) {
		createExtraEmailsDashboard(emails: $emails, parentId: $parentId) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`

export const RESEND_PARENT_INVITATION = gql`
	mutation resendParentInvitation($id: ID!) {
		resendParentInvitation(id: $id) {
			ok
			errors {
				fieldName
				errors
			}
		}
	}
`
