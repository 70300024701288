import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import 'src/styles/main.scss'
import './index.css'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from 'src/apollo/client'
import { SnackbarProvider } from 'notistack'
import Snackbar from 'src/components/elements/Snackbar/Snackbar'
import BackgroundExport from 'src/components/modules/BackgroundExport'
import App from './App'
import { persistor, store } from './store/store'
import { worker } from './mocks/browser'

const initApp = () => {
	ReactDOM.render(
		<React.StrictMode>
			<ApolloProvider client={apolloClient}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<SnackbarProvider
							anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
							maxSnack={1}
							Components={{
								error: Snackbar,
								success: Snackbar,
								default: Snackbar,
								info: Snackbar
							}}
						/>
						<BackgroundExport />
						<Router>
							<App />
						</Router>
					</PersistGate>
				</Provider>
			</ApolloProvider>
		</React.StrictMode>,
		document.getElementById('root')
	)
}

async function bootstrapApp() {
	if (process.env.REACT_APP_TEST_MODE) {
		await worker.start()
	}
	initApp()
}
bootstrapApp()
