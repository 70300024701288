import { BackendErrorsType, DateRangeState, FrontendErrorsType } from 'src/types'
import { addDays, addYears, compareAsc, differenceInDays, format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import {
	ChillTimeType,
	MultipleChillTime,
	MultipleChillTimeDay,
	RecreationTime,
	State,
	Weekday
} from 'src/store/policy/policy.types'
import { ThunkDispatch } from 'redux-thunk'
import { RootState } from 'src/store/store'
import { AnyAction } from 'redux'
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import { ChillRange } from 'src/components/modules/PolicyChillTime/PolicyChillTime'
import {
	MultiChillExpandedTimeRange,
	MultipleChillTimeStartValue
} from 'src/components/modules/PolicyChillTime/modules/NewChillTimeModal/types'
import { enqueueSnackbar } from 'notistack'
import { Dispatch, SetStateAction } from 'react'
import intervalList from './intervalList'

export const phoneMaskRegExp = /^\+\d{8,16}$/
export const serializePhone = (phone: string) => {
	if (!phone) return {}
	return {
		phoneNumber: phone.replace(/\s|[(]|[)]|[-]/gm, '')
	}
}

export const emptyStringToNull = (value, originalValue) => {
	if (typeof originalValue === 'string' && originalValue === '') {
		return null
	}
	return value
}

export const isNullOrUndefined = (value) => {
	return value === null || value === undefined
}

export const isEmptyObject = (obj) => {
	// eslint-disable-next-line
	for (let prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop)) {
			return false
		}
	}
	return true
}

export const copyTextToClipboard = async (text) => {
	if ('clipboard' in navigator) {
		return navigator.clipboard.writeText(text)
	}
	return document.execCommand('copy', true, text)
}

export const transformPolicies = (policies: any) => {
	return policies?.map((policy: any) => {
		return {
			label: policy?.name,
			value: policy?.id
		}
	})
}

export const transformCountries = (data: any) =>
	data?.map((item: any) => {
		return {
			label: item.name,
			value: item.id,
			icon: item.icon
		}
	})

export const transformCities = (data: any) =>
	data?.map((item: any) => {
		return {
			label: item.name,
			value: item.id
		}
	})
export const transformSchools = (data: any) =>
	data?.map((item: any) => {
		return {
			label: item.name,
			id: item.id,
			value: item.id
		}
	})
export const transformPoliciesList = (data: any) =>
	data?.map((item: any) => {
		return {
			label: item.name,
			id: item.id,
			value: item.id
		}
	})

export function capitalizeString(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

const censorWord = (str: string): string => {
	return str.substring(0, 2) + '*'.repeat(str.length - 2)
}

export const censorEmail = (email: string) => {
	let arr = email.split('@')
	return `${censorWord(arr[0])}*${arr[1]}`
}
export const serializeErrors = (errors: BackendErrorsType): FrontendErrorsType => {
	return errors
		.map((err) => {
			return {
				[err.fieldName]: {
					message: err.errors
				}
			}
		})
		.reduce((acc, cur) => ({ ...acc, ...cur }), {})
}

export const serializeNonFieldErrors = (errors: BackendErrorsType): string => {
	if (!errors) {
		return null
	}
	const nonFieldErrors = errors.find((error) => error.fieldName === 'nonFieldErrors')
	if (nonFieldErrors) {
		return nonFieldErrors.errors.join('. ')
	}
	return null
}

export const serializeErrorsOnSteps = (errors: any) => {
	const a = errors.filter((item) => item?.childErrors.length > 0)[0]
	const b = a?.childErrors.map((item) => item.errors[0]).toString()
	return b
}

export const filterArrayValues = (values) => {
	const array = 'array' in values ? values.array : null
	if (!array) return []
	const hasValues = array.filter((item) => item.identifier.trim() !== '')
	const codes = [values.identifier.trim()].concat(hasValues.map((item) => item.identifier.trim()))
	return codes
}

type TTimeValue = {
	hours: number
	minutes: number
}

export const compareTime = (time: TTimeValue, maxTime: TTimeValue): boolean => {
	const { hours, minutes } = time
	const { hours: maxHours, minutes: maxMinutes } = maxTime
	const totalMinutes = minutes + hours * 60
	const totalMaxMinutes = maxMinutes + maxHours * 60
	return totalMinutes <= totalMaxMinutes
}

export const addZero = (time: number): number | string => {
	if (time.toString().length === 1 && time < 10) {
		return `0${time}`
	}
	return time
}

export const getResetDate = (date: Date = new Date()) => new Date(date.setHours(0, 0, 0, 0))
export const getTheEndOfDate = (date: Date = new Date()) => new Date(date.setHours(23, 59, 59, 59))
export const getFormattedDate = (date: Date) => format(date, 'MM/dd/yyy')

export const getPrevPeriodOfRange = (range: DateRangeState) => {
	const { startDate, endDate } = range
	const daysCount = differenceInDays(endDate, startDate)

	return {
		startDate: addDays(startDate, (daysCount + 1) * -1),
		endDate: addDays(startDate, -1)
	}
}

export const getPrevYearRange = (range: DateRangeState): DateRangeState => ({
	startDate: addYears(range.startDate, -1),
	endDate: addYears(range.endDate, -1)
})

export const getISOStringWithTimeZone = (date: Date, timeZone: string) => {
	const dateString = formatInTimeZone(date, timeZone, 'yyyy-MM-dd')
	const timeString = formatInTimeZone(date, timeZone, 'HH:mm:ss')
	const zone = formatInTimeZone(date, timeZone, 'XXX')

	return `${dateString}T${timeString}${zone}`
}

export const isRangeAfterMinDate = (range: DateRangeState, minDate: Date) =>
	compareAsc(range.startDate, minDate) >= 0 && compareAsc(range.endDate, minDate) >= 0

export const getIsoDateRange = (
	{ startDate, endDate }: DateRangeState,
	currentTimeZone: string
) => ({
	isoStarDate: startDate ? getISOStringWithTimeZone(startDate, currentTimeZone) : null,
	isoEndDate: endDate ? getISOStringWithTimeZone(endDate, currentTimeZone) : null
})

export const getFormattedDateRangeString = ({ startDate, endDate }: DateRangeState) =>
	`${getFormattedDate(startDate)} - ${getFormattedDate(endDate)}`

export const getSelectorTimeIntervals = (intervals: string[]) => ({
	startList: intervals?.map((item) => ({ label: item, value: item, id: item })).slice(0, -1),
	endList: intervals?.map((item) => ({ label: item, value: item, id: item })).slice(1)
})

export const getSelectorTimeIntervalsWithIndex = (
	indexes: { startIndex?: number; endIndex?: number } = {}
) => {
	const { startIndex, endIndex } = indexes

	return {
		startList: intervalList
			?.map((item) => ({ label: item, value: item, id: item }))
			.slice(startIndex || 0, endIndex || -1),
		endList: intervalList
			?.map((item) => ({ label: item, value: item, id: item }))
			.slice((startIndex || 0) + 1, endIndex + 1 || intervalList.length)
	}
}

export const findTimeIntervalIndex = (value: typeof intervalList[number]) =>
	intervalList.findIndex((time) => time === value)

export const updateRangeToMinDate = (
	setRange: Dispatch<SetStateAction<DateRangeState>>,
	minDate: Date
) => {
	setRange((prevState) => ({
		...prevState,
		startDate: getResetDate(minDate),
		endDate: getTheEndOfDate(minDate)
	}))
}

const formatTime = (value: string) => {
	return value.split(':').slice(0, 2).join(':')
}

export const generateChillTime = (recreationTime: RecreationTime): ChillTimeType => {
	const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

	if (!recreationTime) {
		return {
			MONDAY: null,
			TUESDAY: null,
			WEDNESDAY: null,
			THURSDAY: null,
			FRIDAY: null,
			SATURDAY: null,
			SUNDAY: null
		} as ChillTimeType
	}

	return days.reduce(
		(acc, day) => ({
			...acc,
			[day]: {
				value: [
					formatTime(recreationTime[`${day}Start`]),
					formatTime(recreationTime[`${day}End`])
				],
				isActive: recreationTime[`${day}IsActive`]
			}
		}),
		{}
	) as ChillTimeType
}

export const generateRecreationTimePayload = (chillTime: ChillTimeType) => {
	if (!Object.keys(chillTime).every((day) => chillTime[day])) {
		return null
	}

	return Object.keys(chillTime).reduce(
		(acc, day) => ({
			...acc,
			[`${day}Start`]: chillTime[day].value[0],
			[`${day}End`]: chillTime[day].value[1],
			[`${day}IsActive`]: chillTime[day].isActive
		}),
		{}
	)
}

export const formatMultipleChillTime = (
	multipleChillTime: MultipleChillTimeStartValue | MultipleChillTime
): MultipleChillTime => {
	return multipleChillTime.map(({ weekDay, isActive, timeIntervals }) => {
		const newInterval: MultipleChillTimeDay['timeIntervals'] = timeIntervals.map(
			({ start, end }) => ({ start, end })
		)

		return { weekDay: weekDay.toUpperCase(), isActive, timeIntervals: newInterval }
	})
}

export const createPolicyPayload = ({
	name,
	services,
	chillTime,
	categories,
	privacies,
	whiteList,
	blackList,
	activeDomains
}: State) => {
	const servicesPayload = services
		.filter((service) => service.isActive)
		.map(({ id, hasRecreationTime }) => ({
			id,
			hasRecreationTime
		}))
	const categoriesPayload = categories
		.filter((category) => category.isActive)
		.map((category) => category.id)
	const privaciesPayload = privacies
		.filter((privacy) => privacy.isActive)
		.map((privacy) => privacy.id)
	const activeDomainsPayload = activeDomains
		.filter(({ domain }) => domain)
		.map(({ domain, activeTimes }) => ({
			domain,
			activeTimes
		}))

	return {
		name: name.value,
		...(!!chillTime.length && { recreationTimes: chillTime }),
		...(servicesPayload.length && { services: servicesPayload }),
		...(categoriesPayload.length && { categories: categoriesPayload }),
		...(privaciesPayload.length && { privacies: privaciesPayload }),
		...(whiteList.value.length && { whiteList: whiteList.value.map((item) => item.name) }),
		...(blackList.value.length && { blackList: blackList.value.map((item) => item.name) }),
		...(activeDomainsPayload.length && { activeDomains: activeDomainsPayload })
	}
}

type AddEditActionType = 'createProfileDashboard' | 'updateProfileDashboard'

type AddEditPolicyDataType = {
	[index in AddEditActionType]: {
		ok: boolean
		errors: Array<{ fieldName: string; errors: string[] }>
	}
}

export const addEditPolicyErrorHandling = (
	actionName: AddEditActionType,
	data: AddEditPolicyDataType,
	dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
	setNameError: ActionCreatorWithOptionalPayload<string>,
	setWhiteListError: ActionCreatorWithOptionalPayload<string[]>,
	setBlackListError: ActionCreatorWithOptionalPayload<string[]>
) => {
	if (!data[actionName].ok) {
		const nameError = data[actionName].errors.find((error) => error.fieldName === 'name')
		const whiteListError = data[actionName].errors.find(
			(error) => error.fieldName === 'whiteList'
		)
		const blackListError = data[actionName].errors.find(
			(error) => error.fieldName === 'blackList'
		)

		if (nameError) {
			dispatch(setNameError(nameError.errors.join(', ')))
			window.scroll(0, 0)
		}

		if (whiteListError) {
			dispatch(setWhiteListError(whiteListError.errors))
		}

		if (blackListError) {
			dispatch(setBlackListError(blackListError.errors))
		}

		return false
	}

	return true
}

export const getChillTimeStartValue = (weekdays: ChillTimeType): ChillTimeType => {
	const startValue: ChillTimeType = {
		MONDAY: null,
		TUESDAY: null,
		WEDNESDAY: null,
		THURSDAY: null,
		FRIDAY: null,
		SATURDAY: null,
		SUNDAY: null
	}
	const weekends = ['saturday', 'sunday']

	Object.keys(weekdays).forEach((key) => {
		const defaultValue = weekends.includes(key)
			? { value: ['09:00', '20:30'], isActive: true }
			: { value: ['18:00', '20:30'], isActive: true }

		startValue[key] = weekdays[key] ?? defaultValue
	})

	return startValue
}

export const getFormattedSingleChillTimeInfo = (chillTimes: MultipleChillTime) => {
	const activeChillTimes = chillTimes.filter((day) => day.isActive)
	const chillRanges: ChillRange[] = []
	let gap = 0

	activeChillTimes.forEach((chillTime, index) => {
		const prevValue = chillTimes[index - 1]?.timeIntervals
		const currentValue = chillTimes[index]?.timeIntervals
		const originDayIndex = chillTimes.findIndex(({ weekDay }) => chillTime.weekDay === weekDay)
		const dayGap = originDayIndex - index
		let isDayGap = false

		if (dayGap !== gap) {
			isDayGap = true
			gap = dayGap
		}

		if (chillRanges.length && !isDayGap && isEqual(prevValue, currentValue)) {
			chillRanges[chillRanges.length - 1].days.push(chillTime.weekDay)
		} else if (currentValue) {
			chillRanges.push({
				days: [chillTime.weekDay],
				range: [chillTime.timeIntervals[0].start, chillTime.timeIntervals[0].end]
			})
		}
	})

	return chillRanges
}

export const getMultipleChillTimeStartValue = (
	multipleChillTimeDays: State['chillTime'],
	excludeServiceInfo?: boolean
): MultipleChillTimeStartValue => {
	const defaultRanges: MultiChillExpandedTimeRange[] = [
		{
			start: '08:00',
			end: '12:00',
			...(!excludeServiceInfo && {
				rangeIndexes: {
					startIndex: 16,
					endIndex: 24
				},
				interval: getSelectorTimeIntervalsWithIndex()
			})
		}
	]

	const weekdays: Weekday[] = [
		'MONDAY',
		'TUESDAY',
		'WEDNESDAY',
		'THURSDAY',
		'FRIDAY',
		'SATURDAY',
		'SUNDAY'
	]

	if (!multipleChillTimeDays?.length) {
		return weekdays.map((day) => ({
			weekDay: day,
			isActive: true,
			timeIntervals: defaultRanges
		}))
	}

	return multipleChillTimeDays.map(
		({ id, weekDay, isActive, timeIntervals, isCustom }): MultipleChillTimeDay => {
			const expandedRanges: MultiChillExpandedTimeRange[] = timeIntervals.map(
				({ start, end }, index, array) => {
					const rangeIndexes = {
						startIndex: findTimeIntervalIndex(start),
						endIndex: findTimeIntervalIndex(end)
					}
					const interval: MultiChillExpandedTimeRange['interval'] =
						getSelectorTimeIntervalsWithIndex({
							...(!!array[index - 1] && {
								startIndex: findTimeIntervalIndex(array[index - 1].end) + 1
							}),
							...(!!array[index + 1] && {
								endIndex: findTimeIntervalIndex(array[index + 1].start)
							})
						})

					return { start, end, rangeIndexes, interval }
				}
			)

			return {
				...(id && { id }),
				...(isCustom && { isCustom }),
				weekDay: weekDay.toUpperCase() as Weekday,
				isActive,
				timeIntervals: expandedRanges
			}
		}
	) as MultipleChillTimeStartValue
}

type ChildNameErrors = { childErrors: { fieldName: string; errors: string[] }[] }

export const serializeChildNameErrors = (errors: ChildNameErrors[], callback?: () => void) => {
	return errors.map((error) => {
		const nameErrors = error.childErrors.find((field) => field.fieldName === 'name')?.errors

		if (callback && !nameErrors) {
			callback()
		}

		return nameErrors ? { message: nameErrors } : null
	})
}

type EditResetChillTimeQueries =
	| 'setRecreationTimeManually'
	| 'setRecreationTime'
	| 'resetRecreationTime'
	| 'resetRecreationTimeManually'
	| 'setDayRecreationTime'

export type EditResetChillTimeReturnType = Pick<
	{
		[key in EditResetChillTimeQueries]?: {
			ok: boolean
			errors: { errors: string[]; fieldName: string }[]
		}
	},
	EditResetChillTimeQueries
>

export type EditResetMutationHandlerParameters = {
	queryName: EditResetChillTimeQueries
	toggleModal: () => void
	refetch: () => Promise<void>
	successSnackbar: {
		message: string
		variant: 'success' | 'info'
	}
}

export const editResetMutationHandler = ({
	queryName,
	successSnackbar,
	refetch,
	toggleModal
}: EditResetMutationHandlerParameters) => {
	return async (data: EditResetChillTimeReturnType) => {
		toggleModal()
		window.scrollTo(0, 0)
		if (data[queryName].ok) {
			await refetch()
			enqueueSnackbar(successSnackbar.message, {
				variant: successSnackbar.variant,
				autoHideDuration: 8000
			})
		}

		if (data[queryName]?.errors.length) {
			enqueueSnackbar(data[queryName].errors[0].errors[0], {
				variant: 'error',
				autoHideDuration: 8000
			})
		}
	}
}
