import { lazy } from 'react'
import ROUTES_NAME from './routesNames'

const HomePage = lazy(() => import('../pages/HomePage'))
const Step1 = lazy(() => import('../pages/Step1'))
const Step2 = lazy(() => import('../pages/Step2'))
const Step3 = lazy(() => import('../pages/Step3'))
const Dashboard = lazy(() => import('../pages/Dashboard'))
const Login = lazy(() => import('../pages/Login'))
const Settings = lazy(() => import('../pages/Settings'))
const VerifyEmail = lazy(() => import('../pages/VerifyEmail'))
const NoFound = lazy(() => import('../pages/NoFound'))
const RecoverPassword = lazy(() => import('../pages/RecoverPassword'))
const PasswordReset = lazy(() => import('../pages/PasswordReset'))
const PaymentStep = lazy(() => import('../pages/PaymentStep'))
const DashboardPayment = lazy(() => import('../pages/DashboardPayment'))
const DashboardCreateChild = lazy(() => import('../pages/DashboardCreateChild'))
const DashboardAddLicence = lazy(() => import('../pages/DashboardAddLicence'))
const DashboardAddParentManually = lazy(() => import('../pages/DashboardAddParent'))
const DashboardAddAdmin = lazy(() => import('../pages/DashboardAddAdmin'))
const GetConnected = lazy(() => import('../pages/GetConnected'))
const DashboardAddChildManually = lazy(() => import('../pages/DashboardAddChildManually'))
const VerifyUser = lazy(() => import('../pages/VerifyUser'))
const AdminRegistration = lazy(() => import('../pages/AdminRegistration'))
const ParentRegistration = lazy(() => import('../pages/ParentRegistration'))
const UnderMaintenance = lazy(() => import('../pages/UnderMaintenance'))
const AddNewPolicy = lazy(() => import('../pages/AddNewPolicy'))
const EditPolicy = lazy(() => import('../pages/EditPolicy'))

export const ROUTES = [
	{
		path: ROUTES_NAME.HOME,
		Element: HomePage
	},
	{
		path: ROUTES_NAME.LOGIN,
		Element: Login
	},
	{
		path: ROUTES_NAME.DASHBOARD,
		Element: Dashboard
	},
	{
		path: ROUTES_NAME.SETTINGS,
		Element: Settings
	},
	{
		path: ROUTES_NAME.STEP1,
		Element: Step1
	},
	{
		path: ROUTES_NAME.PAYMENT_STEP,
		Element: PaymentStep
	},
	{
		path: ROUTES_NAME.STEP2,
		Element: Step2
	},
	{
		path: ROUTES_NAME.STEP3,
		Element: Step3
	},
	{
		path: ROUTES_NAME.RECOVER_PASSWORD,
		Element: RecoverPassword
	},
	{
		path: '/email-verify/:uuid/:token',
		Element: VerifyEmail
	},
	{
		path: '/user-registration/:uuid/:token',
		Element: VerifyUser
	},
	{
		path: '/password-reset/:uuid/:token',
		Element: PasswordReset
	},
	{
		path: ROUTES_NAME.DASHBOARD_ADD_LICENCE,
		Element: DashboardAddLicence
	},
	{
		path: ROUTES_NAME.DASHBOARD_PAYMENT,
		Element: DashboardPayment
	},
	{
		path: ROUTES_NAME.DASHBOARD_CREATE_CHILD,
		Element: DashboardCreateChild
	},
	{
		path: ROUTES_NAME.DASHBOARD_CREATE_PARENT,
		Element: DashboardAddParentManually
	},
	{
		path: ROUTES_NAME.DASHBOARD_CREATE_ADMIN,
		Element: DashboardAddAdmin
	},
	{
		path: ROUTES_NAME.DASHBOARD_GET_CONNECTED,
		Element: GetConnected
	},
	{
		path: ROUTES_NAME.DASHBOARD_ADD_CHILD,
		Element: DashboardAddChildManually
	},
	{
		path: ROUTES_NAME.ADMIN_REGISTRATION,
		Element: AdminRegistration
	},
	{
		path: ROUTES_NAME.PARENT_REGISTRATION,
		Element: ParentRegistration
	},
	{
		path: ROUTES_NAME.UNDER_MAINTENANCE,
		Element: UnderMaintenance
	},
	{
		path: ROUTES_NAME.ADD_NEW_POLICY,
		Element: AddNewPolicy
	},
	{
		path: '/dashboard/policy/:id',
		Element: EditPolicy
	},
	{
		path: '*',
		Element: NoFound
	}
]
export default ROUTES
