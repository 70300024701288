import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
	parentExportId: string
	reportingExportId: string
}

const initialState: State = {
	parentExportId: '',
	reportingExportId: ''
}

const backgroundActivity = createSlice({
	name: 'backgroundActivity',
	initialState,
	reducers: {
		setParentExportId(state: State, action: PayloadAction<string>) {
			state.parentExportId = action.payload
		},
		clearParentExportId(state: State) {
			state.parentExportId = ''
		},
		setReportingExportId(state: State, action: PayloadAction<string>) {
			state.reportingExportId = action.payload
		},
		clearReportingExportId(state: State) {
			state.reportingExportId = ''
		}
	}
})

export const {
	setParentExportId,
	clearParentExportId,
	setReportingExportId,
	clearReportingExportId
} = backgroundActivity.actions
export default backgroundActivity.reducer
