import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { State } from './policiesTable.types'

const initialState: State = {
	page: 1,
	perPage: 10,
	sortBy: null,
	orderBy: null,
	search: ''
}

const policiesTable = createSlice({
	name: 'policiesTable',
	initialState,
	reducers: {
		setPolicyTablePage(state: State, action: PayloadAction<number>) {
			state.page = action.payload
		},
		setPolicyTablePerPage(state: State, action: PayloadAction<number>) {
			state.perPage = action.payload
		},
		setPolicyTableSearch(state: State, action: PayloadAction<string>) {
			state.search = action.payload
		},
		setPolicyTableSortByNameDesc(state: State) {
			state.sortBy = 'DESC'
			state.orderBy = 'NAME'
		},
		setPolicyTableSortByNameAsc(state: State) {
			state.sortBy = 'ASC'
			state.orderBy = 'NAME'
		},
		setPolicyTableSortByActivityDesc(state: State) {
			state.sortBy = 'DESC'
			state.orderBy = 'ISACTIVE'
		},
		setPolicyTableSortByActivityAsc(state: State) {
			state.sortBy = 'ASC'
			state.orderBy = 'ISACTIVE'
		},
		setPolicyTableSortByNumberDesc(state: State) {
			state.sortBy = 'DESC'
			state.orderBy = 'ORDERNUMBER'
		},
		setPolicyTableSortByNumberAsc(state: State) {
			state.sortBy = 'ASC'
			state.orderBy = 'ORDERNUMBER'
		},
		setPolicyTableSortByTypeDesc(state: State) {
			state.sortBy = 'DESC'
			state.orderBy = 'ISBASE'
		},
		setPolicyTableSortByTypeAsc(state: State) {
			state.sortBy = 'ASC'
			state.orderBy = 'ISBASE'
		}
	}
})
export const {
	setPolicyTablePage,
	setPolicyTablePerPage,
	setPolicyTableSortByNameDesc,
	setPolicyTableSortByNameAsc,
	setPolicyTableSortByActivityDesc,
	setPolicyTableSortByActivityAsc,
	setPolicyTableSortByNumberDesc,
	setPolicyTableSortByNumberAsc,
	setPolicyTableSortByTypeDesc,
	setPolicyTableSortByTypeAsc,
	setPolicyTableSearch
} = policiesTable.actions
export const selectPoliciesTable = (state: RootState) => state.policiesTable

export default policiesTable.reducer
