import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { State } from './adminsTable.types'

const initialState: State = {
	page: 1,
	perPage: 10,
	sortBy: 'ASC',
	orderBy: 'NAME',
	search: ''
}

const adminsTable = createSlice({
	name: 'adminsTable',
	initialState,
	reducers: {
		setAdminsTablePage(state: State, action: PayloadAction<number>) {
			state.page = action.payload
		},
		setAdminsTablePerPage(state: State, action: PayloadAction<number>) {
			state.perPage = action.payload
		},
		setAdminsTableSearch(state: State, action: PayloadAction<string>) {
			state.search = action.payload
		},
		setAdminsTableSortByNameDesc(state: State) {
			state.sortBy = 'DESC'
			state.orderBy = 'NAME'
		},
		setAdminsTableSortByNameAsc(state: State) {
			state.sortBy = 'ASC'
			state.orderBy = 'NAME'
		},
		setAdminsTableSortByEmailDesc(state: State) {
			state.sortBy = 'DESC'
			state.orderBy = 'EMAIL'
		},
		setAdminsTableSortByEmailAsc(state: State) {
			state.sortBy = 'ASC'
			state.orderBy = 'EMAIL'
		}
	}
})
export const {
	setAdminsTablePage,
	setAdminsTablePerPage,
	setAdminsTableSortByNameDesc,
	setAdminsTableSortByEmailDesc,
	setAdminsTableSortByNameAsc,
	setAdminsTableSortByEmailAsc,
	setAdminsTableSearch
} = adminsTable.actions
export const selectAdminsTable = (state: RootState) => state.adminsTable

export default adminsTable.reducer
