import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import getSiteInfo from './siteInfo.thunk'

export type State = {
	protectThing1: string
	protectThing2: string
	protectThing3: string
	protectThing4: string
	subtitleSection1IsHtml: boolean
	logo: string
	titleSection1: string
	subtitleSection1: string
	titleSection2: string
	subtitleSection2: string
	titleSection3: string
	titleSection4: string
	subtitleSection4: string
	policies: string
	contactUs: string
	installInstructions: string
	faqs: string
	readMore: string
	orgName: string
	timeZone: string
}

const initialState: State = {
	protectThing1: '',
	protectThing2: '',
	protectThing3: '',
	protectThing4: '',
	subtitleSection1IsHtml: false,
	logo: '',
	titleSection1: '',
	subtitleSection1: '',
	titleSection2: '',
	subtitleSection2: '',
	titleSection3: '',
	titleSection4: '',
	subtitleSection4: '',
	policies: '',
	contactUs: '',
	installInstructions: '',
	faqs: '',
	readMore: '',
	orgName: '',
	timeZone: ''
}
const siteInfo = createSlice({
	name: 'siteInfo',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder.addCase(getSiteInfo.fulfilled, (_, action) => {
			return action.payload.siteInfo
		})
	}
})

export const selectSiteInfo = (state: RootState) => state.siteInfo

export default siteInfo.reducer
