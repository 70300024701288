import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'

type State = {
	steps: number
}

const initialState: State = {
	steps: null
}

const steps = createSlice({
	name: 'siteInfo',
	initialState,
	reducers: {
		setSteps(state: State, action: PayloadAction<number>) {
			state.steps = action.payload
		},
		deleteSteps(state: State) {
			state.steps = null
		}
	}
})

export const { setSteps, deleteSteps } = steps.actions
export const selectSteps = (state: RootState) => state.steps

export default steps.reducer
