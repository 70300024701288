import { gql } from '@apollo/client/core'

export const FRAGMENT_USER = gql`
	fragment User on UserType {
		id
		postCode
		country {
			id
			name
			icon
		}
		city
		phoneNumber
		address
		firstName
		lastName
		address
		email
		licences(inProgress: true) {
			activationDate
			status
			code
			school {
				name
			}
			isValid
			profileCode
		}
		isAuthenticated
		isAdmin
		isSuperadmin
		isExtra
		hasExtra
		isEmailVerified
		extraEmails
	}
`

export const FRAGMENT_CHILD = gql`
	fragment Child on ChildType {
		id
		name
		licence {
			code
			profileCode
			status
			school {
				name
			}
			expirationDaysLeft
			expirationDate
			paymentStatus
			licenceType
			needProceedToPayment
		}
		recreationTimes {
			id
			isActive
			isCustom
			weekDay
			timeIntervals {
				start
				end
			}
		}
		canResetRecreationTime
		policy {
			name
			id
		}
	}
`
