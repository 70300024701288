import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'src/store/store'
import { getBasePolicy, getPolicy } from 'src/store/policy/policy.thunk'
import { formatMultipleChillTime, getMultipleChillTimeStartValue } from 'src/utils'
import { ActiveDomain, BlackWhiteListPayload, MultipleChillTime, State } from './policy.types'

const initialState: State = {
	isLoading: true,
	name: { value: '', errors: null },
	isBase: false,
	isActive: false,
	populateChanges: false,
	orderNumber: null,
	chillTime: [],
	services: [],
	categories: [],
	privacies: [],
	blackList: {
		value: [],
		errors: []
	},
	whiteList: {
		value: [],
		errors: []
	},
	activeDomains: []
}

const policy = createSlice({
	name: 'policy',
	initialState,
	reducers: {
		setName(state: State, { payload }: PayloadAction<string>) {
			state.name = { value: payload, errors: null }
		},
		setNameError(state: State, { payload }: PayloadAction<string>) {
			state.name = { value: state.name.value, errors: { message: payload } }
		},
		setChillTime(state: State, action: PayloadAction<State['chillTime']>) {
			state.chillTime = action.payload
		},
		deleteChillTime(state: State) {
			state.chillTime = []
			state.services = state.services.map((service) => ({
				...service,
				hasRecreationTime: false
			}))
		},
		toggleServicesItem(state: State, action: PayloadAction<string>) {
			const servicesItem = state.services.find((item) => item.id === action.payload)
			servicesItem.isActive = !servicesItem.isActive
		},
		toggleServiceRecreationTime(state: State, action: PayloadAction<string>) {
			const servicesItem = state.services.find((item) => item.id === action.payload)
			servicesItem.hasRecreationTime = !servicesItem.hasRecreationTime
		},
		toggleCategoryItem(state: State, action: PayloadAction<string>) {
			const categoryItem = state.categories.find((item) => item.id === action.payload)
			categoryItem.isActive = !categoryItem.isActive
		},
		toggleProtectionItem(state: State, action: PayloadAction<string>) {
			const protectionItem = state.privacies.find((item) => item.id === action.payload)
			protectionItem.isActive = !protectionItem.isActive
		},
		setBlackWhiteList(state: State, { payload }: PayloadAction<BlackWhiteListPayload>) {
			state.whiteList.value = payload.whiteList
			state.blackList.value = payload.blackList
		},
		setBlackListError(state: State, { payload }: PayloadAction<string[]>) {
			state.blackList = { value: state.blackList.value, errors: payload }
		},
		setWhiteListError(state: State, { payload }: PayloadAction<string[]>) {
			state.whiteList = { value: state.whiteList.value, errors: payload }
		},
		resetAddEditPolicyPage(state: State) {
			state.name = initialState.name
			state.chillTime = initialState.chillTime
			state.whiteList = initialState.whiteList
			state.blackList = initialState.blackList
			state.populateChanges = false
			state.services = []
			state.categories = []
			state.privacies = []
			state.activeDomains = []
		},
		setOrderNumber(state: State, { payload }: PayloadAction<string>) {
			state.orderNumber = Number(payload)
		},
		setIsActive(state: State) {
			state.isActive = !state.isActive
		},
		setPopulateChanges(state: State) {
			state.populateChanges = !state.populateChanges
		},
		addActiveDomain(state: State) {
			state.activeDomains.push({
				id: state.activeDomains.length,
				domain: '',
				activeTimes: getMultipleChillTimeStartValue([], true),
				errors: null
			})
		},
		setActiveDomainName(
			state: State,
			{ payload }: PayloadAction<{ id: number; newName: string }>
		) {
			const foundedDomain = state.activeDomains.find((domain) => domain.id === payload.id)

			if (foundedDomain) {
				foundedDomain.domain = payload.newName
			}
		},
		setActiveDomainChillTime(
			state: State,
			{ payload }: PayloadAction<{ id: number; newChillTime: MultipleChillTime }>
		) {
			const foundedDomain = state.activeDomains.find((domain) => domain.id === payload.id)

			if (foundedDomain) {
				foundedDomain.activeTimes = payload.newChillTime
			}
		},
		removeActiveDomain(state, { payload }: PayloadAction<number>) {
			state.activeDomains = state.activeDomains.filter((domain) => domain.id !== payload)
		},
		setActiveDomainError(
			state,
			{ payload }: PayloadAction<{ id: number; errorMessage: string }>
		) {
			const foundedDomain = state.activeDomains.find((domain) => domain.id === payload.id)

			if (foundedDomain) {
				foundedDomain.errors = { message: payload.errorMessage }
			}
		},
		clearActiveDomainsError(state, { payload }: PayloadAction<number>) {
			const foundedDomain = state.activeDomains.find((domain) => domain.id === payload)

			if (foundedDomain) {
				foundedDomain.errors = null
			}
		}
	},
	extraReducers(builder) {
		builder
			.addCase(getBasePolicy.fulfilled, (state, { payload }) => {
				const { categories, services, privacies, recreationTimes } = payload.baseProfile

				state.categories = categories
				state.services = services
				state.privacies = privacies

				if (recreationTimes) {
					state.chillTime = recreationTimes
				}
			})
			.addCase(getPolicy.fulfilled, (state, { payload }) => {
				const { recreationTimes, activeDomains } = payload.dashboardProfile

				const activeDomainsState: ActiveDomain[] = activeDomains.map(
					({ domain, activeTimes }, id) => ({
						id,
						domain,
						activeTimes: formatMultipleChillTime(activeTimes),
						errors: null
					})
				)

				return {
					...state,
					...payload.dashboardProfile,
					name: { value: payload.dashboardProfile.name, errors: null },
					whiteList: { value: payload.dashboardProfile.whiteList, errors: null },
					blackList: { value: payload.dashboardProfile.blackList, errors: null },
					...(recreationTimes && { chillTime: formatMultipleChillTime(recreationTimes) }),
					activeDomains: activeDomainsState,
					isLoading: false
				}
			})
			.addCase(getPolicy.pending, (state) => {
				return {
					...state,
					isLoading: true
				}
			})
	}
})
export const {
	setName,
	setNameError,
	setChillTime,
	deleteChillTime,
	toggleServicesItem,
	toggleServiceRecreationTime,
	toggleCategoryItem,
	toggleProtectionItem,
	setBlackWhiteList,
	setWhiteListError,
	setBlackListError,
	resetAddEditPolicyPage,
	setOrderNumber,
	setIsActive,
	setPopulateChanges,
	addActiveDomain,
	setActiveDomainName,
	setActiveDomainChillTime,
	removeActiveDomain,
	setActiveDomainError,
	clearActiveDomainsError
} = policy.actions
export const selectPolicy = (state: RootState) => state.policy

export default policy.reducer
