import { Suspense, useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { ApolloError, ServerParseError, useQuery } from '@apollo/client'
import finishRegisterRequired from 'src/hoc/finishRegisterRequired'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import routes from './router'
import PageLoading from './components/modules/PageLoading'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { checkAuth } from './store/auth/auth.thunk'
import getSiteInfo from './store/siteInfo/siteInfo.thunk'
import ROUTES_NAME from './router/routesNames'
import { selectUser } from './store/auth/auth.slice'
import { GET_ZENDESK_API_KEY } from './graphql/query'

const App = () => {
	const dispatch = useAppDispatch()
	const { isAuthenticated, email } = useAppSelector(selectUser)
	const { pathname, hash } = useLocation()
	const navigate = useNavigate()

	const { data } = useQuery(GET_ZENDESK_API_KEY)

	useEffect(() => {
		if (data?.siteInfo?.zendeskApiKey) {
			const script = document.createElement('script')
			script.src = `https://static.zdassets.com/ekr/snippet.js?key=${data.siteInfo.zendeskApiKey}`
			script.id = 'ze-snippet'
			const id = data?.siteInfo?.zendeskSchoolInputId
			const isAdmin = data?.user?.isAdmin
			const firstName = data?.user?.firstName
			const lastName = data?.user?.lastName
			const isSuperadmin = data?.user?.isSuperadmin
			const schools = data?.user?.schools?.join(', ')
			const getSchool = () => {
				if (isSuperadmin) return 'Superadmin'
				if (isAdmin) return `${schools}. (Admin)`
				return schools
			}
			/* @ts-ignore */
			window.zESettings = {
				webWidget: {
					contactForm: {
						fields: [
							{ id, prefill: { '*': getSchool() } },
							{ id: 'email', prefill: { '*': data?.user?.email } },
							{ id: 'name', prefill: { '*': `${firstName || ''} ${lastName || ''}` } }
						]
					}
				}
			}

			document.body.appendChild(script)
		}
	}, [data])

	useEffect(() => {
		if (isAuthenticated && !email) {
			dispatch(checkAuth()).then((result) => {
				if (result.payload instanceof ApolloError) {
					const { networkError } = result.payload
					if (
						networkError &&
						[502, 503, 504].includes((networkError as ServerParseError).statusCode)
					) {
						navigate(ROUTES_NAME.UNDER_MAINTENANCE)
					}
				}
			})
		}
		dispatch(getSiteInfo())
	}, [])

	useEffect(() => {
		const handleGlobalErrors = (event) => {
			if (event.type === 'graphQLError') {
				enqueueSnackbar('Something went wrong. Please, check the console for details', {
					variant: 'error'
				})
			}
		}

		window.addEventListener('graphQLError', handleGlobalErrors)

		return () => {
			window.removeEventListener('graphQLError', handleGlobalErrors)
		}
	}, [])

	useEffect(() => {
		closeSnackbar()
	}, [pathname, hash])

	return (
		<Suspense fallback={<PageLoading />}>
			<Routes>
				{routes.map(({ path, Element }) => {
					const whiteLinkList = [
						'/finish-parent-registration',
						'/admin-registration',
						'/step1'
					]
					const Component = whiteLinkList.includes(path)
						? Element
						: finishRegisterRequired(Element)

					return <Route path={path} element={<Component />} key={path} />
				})}
			</Routes>
		</Suspense>
	)
}

export default App
