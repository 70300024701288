import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { serializeErrors, serializeNonFieldErrors } from 'src/utils'
import { RequestError } from 'src/graphql/types'
import type { RootState } from '../store'
import { Payload, State } from './auth.types'
import {
	checkAuth,
	confirmResetPassword,
	confirmUserEmail,
	createUser,
	loginUserByToken,
	loginUser,
	logoutUser,
	registerAdmin,
	registerUser,
	updateUser,
	updateAdmin
} from './auth.thunk'

const initialState: State = {
	user: {
		address: null,
		postCode: null,
		country: null,
		city: null,
		phoneNumber: null,
		licences: null,
		firstName: null,
		lastName: null,
		email: null,
		extraLicences: null,
		isAuthenticated: false,
		isSuperadmin: false,
		isAdmin: false,
		isExtra: false,
		isEmailVerified: false,
		hasExtra: false,
		allowParentControlRecreationTime: false,
		extraEmails: []
	},
	fieldErrors: null,
	nonFieldError: null,
	requestError: null,
	loading: false,
	isRefreshing: false
}

const handlePending = (state: State) => {
	state.loading = true
}

const clearErrors = (state: State) => {
	state.requestError = null
	state.nonFieldError = null
	state.fieldErrors = null
}
const handleRejected = (state: State, action: PayloadAction<RequestError>) => {
	state.loading = false
	state.isRefreshing = false
	state.requestError = action.payload.message
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		addParentExtraEmail(state: State, action: PayloadAction<string>) {
			state.user.hasExtra = true
			state.user.extraEmails.push(action.payload)
		}
	},
	extraReducers(builder) {
		builder
			.addCase(confirmUserEmail.rejected, handleRejected)
			.addCase(updateAdmin.rejected, handleRejected)
			.addCase(logoutUser.rejected, handleRejected)
			.addCase(createUser.rejected, handleRejected)
			.addCase(confirmResetPassword.rejected, handleRejected)
			.addCase(loginUser.rejected, handleRejected)
			.addCase(registerAdmin.rejected, handleRejected)
			.addCase(registerUser.rejected, handleRejected)
			.addCase(updateUser.rejected, handleRejected)
			.addCase(logoutUser.pending, handlePending)
			.addCase(updateAdmin.pending, handlePending)
			.addCase(checkAuth.pending, (state: State) => {
				state.isRefreshing = true
			})
			.addCase(confirmUserEmail.pending, handlePending)
			.addCase(confirmResetPassword.pending, handlePending)
			.addCase(createUser.pending, handlePending)
			.addCase(registerAdmin.pending, handlePending)
			.addCase(updateUser.pending, handlePending)
			.addCase(registerUser.pending, handlePending)
			.addCase(loginUserByToken.pending, handlePending)
			.addCase(loginUser.pending, handlePending)
			.addCase(checkAuth.rejected, handleRejected)
			.addCase(loginUserByToken.rejected, handleRejected)
			.addCase(logoutUser.fulfilled, (state) => {
				state.loading = false
				state.isRefreshing = false
				clearErrors(state)
				state.user = initialState.user
			})
			.addCase(checkAuth.fulfilled, (state, action: PayloadAction<Payload>) => {
				state.loading = false
				state.isRefreshing = false
				state.user = action.payload.user
				clearErrors(state)
			})
			.addCase(loginUser.fulfilled, (state, { payload }) => {
				state.loading = false
				state.requestError = null
				if (payload.loginUser) {
					if (payload.loginUser.ok) {
						state.user = payload.loginUser.user
						state.nonFieldError = null
						state.fieldErrors = null
					} else {
						state.user = initialState.user
						state.nonFieldError = serializeNonFieldErrors(payload.loginUser.errors)
						state.fieldErrors = serializeErrors(payload.loginUser.errors)
					}
				}
			})
			.addCase(registerAdmin.fulfilled, (state, { payload }) => {
				state.loading = false
				state.requestError = null
				if (payload.registerAdmin) {
					if (payload.registerAdmin.ok) {
						state.user = payload.registerAdmin.user
						state.nonFieldError = null
						state.fieldErrors = null
					} else {
						state.user = initialState.user
						state.nonFieldError = serializeNonFieldErrors(payload.registerAdmin.errors)
						state.fieldErrors = serializeErrors(payload.registerAdmin.errors)
					}
				}
			})
			.addCase(registerUser.fulfilled, (state, { payload }) => {
				state.loading = false
				state.requestError = null
				if (payload.registerUser) {
					if (payload.registerUser.ok) {
						state.user = payload.registerUser.user
						state.nonFieldError = null
						state.fieldErrors = null
					} else {
						state.user = initialState.user
						state.nonFieldError = serializeNonFieldErrors(payload.registerUser.errors)
						state.fieldErrors = serializeErrors(payload.registerUser.errors)
					}
				}
			})
			.addCase(confirmResetPassword.fulfilled, (state, { payload }) => {
				state.loading = false
				state.requestError = null
				if (payload.confirmResetUserPassword) {
					if (payload.confirmResetUserPassword.ok) {
						state.user = payload.confirmResetUserPassword.user
						state.nonFieldError = null
						state.fieldErrors = null
					} else {
						state.nonFieldError = serializeNonFieldErrors(
							payload.confirmResetUserPassword.errors
						)
						state.fieldErrors = serializeErrors(payload.confirmResetUserPassword.errors)
					}
				}
			})
			.addCase(updateUser.fulfilled, (state, { payload }) => {
				state.loading = false
				state.requestError = null
				if (payload.updateUser) {
					if (payload.updateUser.ok) {
						state.user = payload.updateUser.user
						state.nonFieldError = null
						state.fieldErrors = null
					} else {
						state.nonFieldError = serializeNonFieldErrors(payload.updateUser.errors)
						state.fieldErrors = serializeErrors(payload.updateUser.errors)
					}
				}
			})
			.addCase(createUser.fulfilled, (state, { payload }) => {
				state.loading = false
				state.requestError = null
				if (payload.createUser) {
					if (payload.createUser.ok) {
						state.user = payload.createUser.user
						state.nonFieldError = null
						state.fieldErrors = null
					} else {
						state.user = initialState.user
						state.nonFieldError = serializeNonFieldErrors(payload.createUser.errors)
						state.fieldErrors = serializeErrors(payload.createUser.errors)
					}
				}
			})
			.addCase(confirmUserEmail.fulfilled, (state, { payload }) => {
				state.loading = false
				state.requestError = null
				if (payload.confirmUserEmail) {
					if (payload.confirmUserEmail.ok) {
						state.nonFieldError = null
						state.fieldErrors = null
						if (payload.confirmUserEmail.user.isAuthenticated) {
							state.user = payload.confirmUserEmail.user
						}
					} else {
						state.nonFieldError = serializeNonFieldErrors(
							payload.confirmUserEmail.errors
						)
						state.fieldErrors = serializeErrors(payload.confirmUserEmail.errors)
					}
				}
			})
			.addCase(loginUserByToken.fulfilled, (state, { payload }) => {
				state.loading = false
				state.requestError = null
				if (payload.loginUserByToken) {
					if (payload.loginUserByToken.ok) {
						state.nonFieldError = null
						state.fieldErrors = null
						if (payload.loginUserByToken.user.isAuthenticated) {
							state.user = payload.loginUserByToken.user
						}
					} else {
						state.nonFieldError = serializeNonFieldErrors(
							payload.loginUserByToken.errors
						)
						state.fieldErrors = serializeErrors(payload.loginUserByToken.errors)
					}
				}
			})
			.addCase(updateAdmin.fulfilled, (state, { meta, payload }) => {
				state.loading = false
				state.requestError = null
				if (payload.updateAdmin) {
					if (payload.updateAdmin.ok) {
						state.nonFieldError = null
						state.fieldErrors = null
						state.user.firstName = meta.arg.firstName
						state.user.lastName = meta.arg.lastName
					} else {
						state.nonFieldError = serializeNonFieldErrors(payload.updateAdmin.errors)
						state.fieldErrors = serializeErrors(payload.updateAdmin.errors)
					}
				}
			})
	}
})

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState): State['user'] => state.auth.user
export const selectUserErrors = (state: RootState) => {
	return {
		fieldErrors: state.auth.fieldErrors,
		nonFieldError: state.auth.nonFieldError,
		requestError: state.auth.requestError
	}
}
export const selectUserLoading = (state: RootState) => state.auth.loading
export const selectUserRefreshing = (state: RootState) => state.auth.isRefreshing

// export actions
export const { addParentExtraEmail } = authSlice.actions

export default authSlice.reducer
