import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PageLoading from 'src/components/modules/PageLoading'
import ROUTES_NAME from 'src/router/routesNames'
import { useAppSelector } from 'src/store/hooks'
import { selectUser, selectUserRefreshing } from 'src/store/auth/auth.slice'

const finishRegisterRequired = (Component) =>
	// eslint-disable-next-line react/function-component-definition
	function Comp(props) {
		const navigate = useNavigate()
		const user = useAppSelector(selectUser)
		const loading = useAppSelector(selectUserRefreshing)
		useEffect(() => {
			if (!loading && user.isAuthenticated && (!user.firstName || !user.lastName)) {
				navigate(
					user.isAdmin ? ROUTES_NAME.ADMIN_REGISTRATION : ROUTES_NAME.PARENT_REGISTRATION
				)
			}
		}, [user.isAuthenticated, loading])

		if (loading) {
			return <PageLoading />
		}
		return <Component {...props} />
	}

export default finishRegisterRequired
